import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    py: ["2.5", "5"],
    "& ~ &": {
        borderTopWidth: "1px",
        borderColor: "inherit",
    },
};

export const panelSectionStyles = {
    baseStyle,
};
