import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    fontWeight: "bold",
    lineHeight: "base",
    letterSpacing: "normal",
};

const sizes: Record<string, SystemStyleObject> = {
    h1: {
        fontSize: ["mobileH1", "tabletH1", "desktopH1"],
        lineHeight: "none",
        letterSpacing: "normal",
    },
    h2: {
        fontSize: ["mobileH2", "tabletH2", "desktopH2"],
        lineHeight: ["none", "short", "none"],
        letterSpacing: "normal",
    },
    h3: {
        fontSize: ["mobileH3", "tabletH3", "desktopH3"],
        lineHeight: ["shorter", "short", "short"],
        letterSpacing: "wide",
    },
    h4: {
        fontSize: ["mobileH4", "tabletH4", "desktopH4"],
        lineHeight: "base",
        letterSpacing: "wide",
        fontWeight: "normal",
    },
    h5: {
        fontSize: ["mobileH5", "tabletH5", "desktopH5"],
        lineHeight: "base",
        letterSpacing: "wide",
    },
    h6: {
        fontSize: ["mobileH6", "tabletH6", "desktopH6"],
        lineHeight: "base",
        letterSpacing: "normal",
    },
};

const defaultProps = {
    size: "h1",
};

export const headingStyles = {
    baseStyle,
    sizes,
    defaultProps,
};
