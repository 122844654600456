import { cssVar } from "@chakra-ui/theme-tools";
import type { SystemStyleObject } from "@chakra-ui/react";
import { focusOutline } from "../../../css/02.focus/focus.styles";

const $size = cssVar("close-button-size");

const baseStyle: SystemStyleObject = {
    w: [$size.reference],
    h: [$size.reference],
    borderRadius: "full",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
        boxShadow: "none",
    },
    _hover: { bg: `blackAlpha.100` },
    _active: { bg: `blackAlpha.200` },
    _focus: {
        ...focusOutline,
    },
};

const sizes: Record<string, SystemStyleObject> = {
    lg: {
        [$size.variable]: "40px",
        fontSize: "16px",
    },
    md: {
        [$size.variable]: "32px",
        fontSize: "12px",
    },
    sm: {
        [$size.variable]: "24px",
        fontSize: "10px",
    },
};

const defaultProps = {
    size: "md",
};

export const closeButtonStyles = {
    baseStyle,
    sizes,
    defaultProps,
};
