import type { SystemStyleFunction } from "@chakra-ui/theme-tools";
import { cssVar } from "@chakra-ui/theme-tools";

const $bg = cssVar("tooltip-bg");
const $arrowBg = cssVar("popper-arrow-bg");

const baseStyle: SystemStyleFunction = () => {
    const bg = "black";
    return {
        [$bg.variable]: `colors.${bg}`,
        px: "4",
        py: "2",
        bg: [$bg.reference],
        [$arrowBg.variable]: [$bg.reference],
        color: "whiteAlpha.900",
        borderRadius: "md",
        fontWeight: "medium",
        fontSize: "sm",
        boxShadow: "md",
        maxW: "320px",
        zIndex: "tooltip",
    };
};

export const tooltipStyles = {
    baseStyle,
};
