import type {
    PartsStyleFunction,
    PartsStyleInterpolation,
    PartsStyleObject,
    SystemStyleObject,
} from "@chakra-ui/theme-tools";
import type { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { surfaceAnatomy } from "./surface.anatomy";

const baseStyleSurfaceHeader: SystemStyleObject = {
    borderWidth: "1px",
    borderBottomWidth: 0,
};

const baseStyleSurfaceSection: SystemStyleObject = {
    borderWidth: "1px",
};

const baseStyle: PartsStyleObject<typeof surfaceAnatomy> = {
    root: {},
    header: baseStyleSurfaceHeader,
    section: baseStyleSurfaceSection,
};

const variantBorder: PartsStyleFunction<typeof surfaceAnatomy> = (props) => {
    const { colorScheme } = props;

    if (colorScheme === "gray") {
        return {
            header: {
                bg: "gray.200",
                color: "black",
                borderColor: "gray.200",
            },
            section: {
                bg: "gray.100",
                color: "black",
                borderColor: "gray.200",
            },
        };
    }

    if (colorScheme === "white") {
        return {
            header: {
                bg: "gray.200",
                color: "black",
                borderColor: "gray.200",
            },
            section: {
                bg: "white",
                color: "black",
                borderColor: "gray.200",
            },
        };
    }

    return {
        header: {
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.600`,
            borderColor: `${colorScheme}.600`,
        },
        section: {
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.600`,
            borderColor: `${colorScheme}.600`,
        },
    };
};

const variantStrongBorder: PartsStyleFunction<typeof surfaceAnatomy> = (props) => {
    const { colorScheme } = props;

    if (colorScheme === "white" || colorScheme === "gray") {
        return {
            header: {
                ...variantBorder(props),
                borderColor: "black",
            },
            section: {
                ...variantBorder(props),
                borderColor: "black",
            },
        };
    }

    return {
        header: {
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.800`,
            borderColor: `${colorScheme}.800`,
        },
        section: {
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.800`,
            borderColor: `${colorScheme}.800`,
        },
    };
};

const variantNoBorder: PartsStyleFunction<typeof surfaceAnatomy> = (props) => {
    const { colorScheme } = props;

    if (colorScheme === "white") {
        return {
            header: {
                bg: "white",
                color: "black",
                borderColor: "white",
            },
            section: {
                bg: "white",
                color: "black",
                borderColor: "white",
            },
        };
    }

    return {
        header: {
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.800`,
            borderColor: `${colorScheme}.50`,
        },
        section: {
            bg: `${colorScheme}.50`,
            color: `${colorScheme}.800`,
            borderColor: `${colorScheme}.50`,
        },
    };
};

const variants: Record<string, PartsStyleInterpolation<typeof surfaceAnatomy>> = {
    noBorder: variantNoBorder,
    border: variantBorder,
    strongBorder: variantStrongBorder,
};

const sizes: Record<string, PartsStyleInterpolation<typeof surfaceAnatomy>> = {
    sm: {
        header: {
            px: ["2.5", "5"],
            py: ["1", "2.5"],
        },
        section: {
            p: ["2.5", "5"],
        },
    },
    md: {
        header: {
            px: ["5", "10"],
            py: ["2.5", "5"],
        },
        section: {
            p: ["5", "10"],
        },
    },
};

const defaultProps = {
    size: "md",
    variant: "border",
    colorScheme: "white",
};

export const surfaceStyles: ComponentMultiStyleConfig = {
    baseStyle,
    sizes,
    variants,
    defaultProps,
    parts: surfaceAnatomy.keys,
};
