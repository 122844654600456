import { ChakraProvider } from "@chakra-ui/provider";
import type { ECCOTheme } from "./theme";
import { theme as baseTheme } from "./theme";
import type { FC, ReactNode } from "react";

export interface ThemeProviderProps {
    theme?: ECCOTheme;
    children?: ReactNode;
}

const ThemeProvider: FC<ThemeProviderProps> = ({ theme = baseTheme, children }) => (
    <ChakraProvider theme={theme}>{children}</ChakraProvider>
);

export { ThemeProvider };
