import { radioAnatomy as parts } from "@chakra-ui/anatomy";
import type {
    PartsStyleFunction,
    PartsStyleObject,
    SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { checkboxStyles } from "../checkbox/Checkbox.styles";

const baseStyleControl: SystemStyleFunction = (props) => {
    const { control = {} } = checkboxStyles.baseStyle(props);
    const { colorScheme: c } = props;

    const bg = c === "white" ? "black" : "white";
    const borderColor = c === "white" ? "black" : c === "black" ? "black" : `${c}.500`;
    const color = c === "white" ? "black" : c === "black" ? "black" : `${c}.500`;

    return {
        ...control,
        color,
        borderRadius: "full",
        _checked: {
            ...(control as any)["_checked"],
            bg,
            borderColor,
            color,
            position: "relative",

            _hover: {
                color,
                borderColor,
            },
            _disabled: {
                border: "none",
            },
            _before: {
                content: `""`,
                display: "inline-block",
                pos: "absolute",
                w: "50%",
                h: "50%",
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                borderRadius: "50%",
                bg: "currentColor",
            },
        },
        _disabled: {
            _hover: {
                border: "none",
            },
            _checked: {
                color: "white",
            },
        },
    };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    label: checkboxStyles.baseStyle(props).label,
    container: checkboxStyles.baseStyle(props).container,
    control: baseStyleControl(props),
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    md: {
        control: { w: 4, h: 4 },
        label: { fontSize: "md" },
    },
    lg: {
        control: { w: 5, h: 5 },
        label: { fontSize: "lg" },
    },
    sm: {
        control: { width: 3, height: 3 },
        label: { fontSize: "sm" },
    },
};

const defaultProps = {
    size: "md",
    colorScheme: "black",
};

export const radioStyles = {
    parts: parts.keys,
    baseStyle,
    sizes,
    defaultProps,
};
