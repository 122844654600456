import type { Styles } from "@chakra-ui/theme-tools";
import { PX_BASE } from "./design-tokens/PX_BASE";

export const globalStyles: Styles = {
    global: {
        body: {
            color: "black",
            bg: "white",
            transitionProperty: "background-color",
            transitionDuration: "normal",
            lineHeight: "base",
            height: "100%",
            fontSize: PX_BASE,
        },
        "*::placeholder": {
            color: "gray.400",
        },
        "*, *::before, &::after": {
            borderColor: "gray.200",
            wordWrap: "break-word",
        },
        // We need this styles to overwrite the user style agent for safari, otherwise in the animations we will have margins.
        "h2, p": {
            marginBlockStart: 0,
            marginBlockEnd: 0,
            marginInlineStart: 0,
            marginInlineEnd: 0,
        },
        ".grecaptcha-badge": {
            visibility: "hidden",
            opacity: 0,
            height: "0 !important",
        },
        // Styles for printable/PDF version
        "@media print": {
            /* We need to hide usp banner, nav and footer from the PDF version, due legal requirements */
            "#navigation, #footer": {
                display: "none",
            },
            '[data-testid="USPBanner"]': {
                display: "none !important",
            },
            "#card-generic": {
                display: "none",
            },
            // Accordions need to be open when is printable version, if we don't pass important the values will be overwritten by styles on the component level
            ".chakra-collapse": {
                display: "block !important",
                height: "100% !important",
                opacity: "1 !important",
            },
        },

        'input[type="search" i]:enabled:read-write:-webkit-any(:focus, :hover)::-webkit-search-cancel-button':
            {
                opacity: 0,
            },
    },
};
