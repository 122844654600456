import { PX_BASE } from "../../PX_BASE";

const baseScale = {
    desktopH1: "72px",
    desktopH2: "40px",
    desktopH3: "24px",
    desktopH4: PX_BASE,
    desktopH5: PX_BASE,
    desktopH6: PX_BASE,

    tabletH1: "72px",
    tabletH2: "40px",
    tabletH3: "22px",
    tabletH4: PX_BASE,
    tabletH5: PX_BASE,
    tabletH6: PX_BASE,

    mobileH1: "32px",
    mobileH2: "24px",
    mobileH3: "20px",
    mobileH4: "14px",
    mobileH5: "14px",
    mobileH6: "14px",

    desktopLinkL1: PX_BASE,
    desktopLinkL2: PX_BASE,
    desktopLinkL3: PX_BASE,
    desktopLinkL4: PX_BASE,

    tabletLinkL1: PX_BASE,
    tabletLinkL2: PX_BASE,
    tabletLinkL3: PX_BASE,
    tabletLinkL4: PX_BASE,

    mobileLinkL1: "14px",
    mobileLinkL2: "14px",
    mobileLinkL3: "14px",
    mobileLinkL4: "14px",

    desktopBodyB1: "24px",
    desktopBodyB2: PX_BASE,
    desktopBodyB3: PX_BASE,
    desktopBodyB4: "12px",

    tabletBodyB1: "20px",
    tabletBodyB2: PX_BASE,
    tabletBodyB3: PX_BASE,
    tabletBodyB4: "12px",

    mobileBodyB1: "16px",
    mobileBodyB2: "14px",
    mobileBodyB3: "14px",
    mobileBodyB4: "12px",

    desktopNumberOnIcon: "8px",
    tabletNumberOnIcon: "8px",
    mobileNumberOnIcon: "8px",
};

export const scale = {
    ...baseScale,
    desktopBodyTextLarge: baseScale.desktopBodyB1,
    desktopBodyTextNormal: baseScale.desktopBodyB2,
    desktopBodyTextSmall: baseScale.desktopBodyB3,
    desktopBodyTextTiny: baseScale.desktopBodyB4,
    desktopHeading5: baseScale.desktopH5,
    desktopHeading4: baseScale.desktopH4,
    desktopHeading3: baseScale.desktopH3,
    desktopHeading2: baseScale.desktopH2,
    desktopHeading1: baseScale.desktopH1,
    desktopNumberOnIcon: baseScale.desktopNumberOnIcon,

    tableHeading3: baseScale.tabletH3,

    mobileBodyTextLarge: baseScale.mobileBodyB1,
    mobileBodyTextNormal: baseScale.mobileBodyB2,
    mobileBodyTextSmall: baseScale.mobileBodyB3,
    mobileBodyTextTiny: baseScale.mobileBodyB4,
    mobileHeading5: baseScale.mobileH5,
    mobileHeading4: baseScale.mobileH4,
    mobileHeading3: baseScale.mobileH3,
    mobileHeading2: baseScale.mobileH2,
    mobileHeading1: baseScale.mobileH1,
    mobileNumberOnIcons: baseScale.mobileNumberOnIcon,
};

export default scale;
