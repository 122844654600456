import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    fontWeight: "normal",
    letterSpacing: "normal",
    whiteSpace: "pre-line",
};

const sizes: Record<string, SystemStyleObject> = {
    b1: {
        fontSize: ["mobileBodyB1", "tabletBodyB1", "desktopBodyB1"],
        fontWeight: "bold",
        lineHeight: "short",
    },
    b2: {
        fontSize: ["mobileBodyB2", "tabletBodyB2", "desktopBodyB2"],
        fontWeight: "bold",
        lineHeight: "base",
    },
    b3: {
        fontSize: ["mobileBodyB3", "tabletBodyB3", "desktopBodyB3"],
        lineHeight: "base",
    },
    b4: {
        fontSize: ["mobileBodyB4", "tabletBodyB4", "desktopBodyB4"],
        lineHeight: ["base", "tall", "tall"],
    },
};
const defaultProps = {
    size: "b3",
};

export const textStyles = {
    baseStyle,
    sizes,
    defaultProps,
};
