import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const spacerStyles: ComponentStyleConfig = {
    baseStyle: {
        display: "block",
        width: "100%",
        height: "100%",
    },
    sizes: {
        sm: {
            height: ["20px", "40px", "40px"],
        },
        md: {
            height: ["40px", "80px", "80px"],
        },
        lg: {
            height: ["80px", "120px", "120px"],
        },
    },

    defaultProps: {
        size: "md",
    },
};
