import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    switchAnatomy.keys
);

const baseStyle = definePartsStyle({
    container: {},
    thumb: {
        bg: "white",
        _checked: {
            transform: "translateX(24px)",
        },
    },
    track: {
        w: "44px",
        borderRadius: 15,
        bg: "gray.500",
        _checked: {
            bg: "black",
        },
    },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
