import { breadcrumbAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
    container: { width: "100%", position: "relative", mb: 2 },
    item: {
        fontSize: "sm",
        lineHeight: "normal",
        color: "black",
    },
};

export const breadcrumbStyles = {
    parts: parts.keys,
    baseStyle,
};
