import type { Breakpoints } from "@chakra-ui/theme-tools";

export type ECCOBreakpoints = {
    base: "0em";
    sm: string;
    md: string;
    lg: string;
    xl: string;
    "2xl": string;
    "3xl": string;
    "4xl": string;
    "5xl": string;
};

const breakpoints: Breakpoints<ECCOBreakpoints> = {
    base: "0em",
    sm: "480px",
    md: "810px",
    lg: "992px",
    xl: "1280px",
    "2xl": "1440px",
    "3xl": "1920px",
    "4xl": "2048px",
    "5xl": "3840px",
};

export default breakpoints;
