import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    maxW: "100%",
};

const sizes: Record<string, SystemStyleObject> = {
    default: {
        w: "eccoLogo",
        h: "auto",
    },
};

const defaultProps = {
    size: "default",
};

export const eccoLogoStyles = {
    baseStyle,
    sizes,
    defaultProps,
};
