import { alertAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject, PartsStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleObject<typeof parts> = {
    container: {
        px: 4,
        py: 3,
    },
    title: {
        fontWeight: "bold",
        lineHeight: 6,
        marginEnd: 2,
    },
    description: {
        lineHeight: 6,
        marginEnd: 2,
    },
    icon: {
        flexShrink: 0,
        marginEnd: "unset",
        marginStart: "auto",
        w: 5,
        h: 6,
    },
};

const variantSubtle: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props;
    const color = c === "gray" || c === "white" ? "black" : c === "black" ? "white" : `${c}.500`;
    const bg = c === "white" || c === "black" ? c : `${c}.50`;

    return {
        container: { bg, color },
        icon: { color },
    };
};

const variantSolid: PartsStyleFunction<typeof parts> = (props) => {
    const { colorScheme: c } = props;
    const color = c === "white" ? "black" : "white";
    const bg = c === "white" || c === "black" ? c : c === "gray" ? "gray.800" : `${c}.500`;

    return {
        container: {
            bg,
            color,
        },
    };
};

const variants = {
    subtle: variantSubtle,
    solid: variantSolid,
};

const defaultProps = {
    variant: "subtle",
    colorScheme: "yellow",
};

export const alertStyles = {
    parts: parts.keys,
    baseStyle,
    variants,
    defaultProps,
};
