import { selectAnatomy as parts } from "@chakra-ui/anatomy";
import type {
    PartsStyleFunction,
    PartsStyleObject,
    SystemStyleFunction,
    SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { mergeWith } from "@chakra-ui/utils";
import { inputStyles } from "../input/Input.styles";

const baseStyleField: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    return {
        ...inputStyles.baseStyle.field,
        bg: c,
        appearance: "none",
        paddingBottom: "1px",
        lineHeight: "normal",
    };
};

const baseStyleIcon: SystemStyleObject = {
    width: "1.5rem",
    height: "100%",
    insetEnd: "0.5rem",
    position: "relative",
    color: "currentColor",
    fontSize: "1.25rem",
    _disabled: {
        opacity: 0.5,
    },
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    field: baseStyleField(props),
    icon: baseStyleIcon,
});

const iconSpacing = { paddingInlineEnd: "2rem" };

const sizes: Record<string, PartsStyleObject<typeof parts>> = mergeWith({}, inputStyles.sizes, {
    lg: {
        field: iconSpacing,
    },
    md: {
        field: iconSpacing,
    },
    sm: {
        field: iconSpacing,
    },
    xs: {
        field: iconSpacing,
        icon: { insetEnd: "0.25rem" },
    },
});

const variants = {
    outline: inputStyles.variants.outline,
};
export const selectStyles = {
    parts: parts.keys,
    baseStyle,
    sizes,
    variants: variants,
    defaultProps: inputStyles.defaultProps,
};
