const fallbackFonts = `Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

const unicaFontStack = `Unica77, ${fallbackFonts}`;

const fontFamilies = {
    body: unicaFontStack,
    heading: unicaFontStack,
};

export default fontFamilies;
