import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    opacity: 1,
    borderColor: "inherit",
};

const variantSolid: SystemStyleObject = {
    borderStyle: "solid",
};

const variants = {
    solid: variantSolid,
};

const defaultProps = {
    variant: "solid",
};

export const dividerStyles = {
    baseStyle,
    variants,
    defaultProps,
};
