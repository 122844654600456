export const scale = {
    normal: "normal",
    none: 1,
    shorter: 1.1,
    short: 1.2,
    base: 1.3,
    default: 1.27,
    tall: 1.4,
    taller: 1.4,
};

export default scale;
