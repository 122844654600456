import type { PartsStyleFunction, SystemStyleObject } from "@chakra-ui/theme-tools";
import { selectDropdownAnatomy } from "./SelectDropdown.anatomy";
import { focusOutline } from "../../../css/02.focus/focus.styles";

const baseStyleButton = (): SystemStyleObject => {
    return {
        bgColor: "gray.50",
        paddingLeft: 4,
        paddingRight: 4,
        gap: 4,
        fontSize: ["desktopH4"],
        _hover: {
            bgColor: "gray.100",
            borderColor: "gray.100",
            _disabled: { borderColor: "gray.100" },
        },
        _active: {
            _disabled: { borderColor: "gray.100" },
            _hover: {
                bgColor: "gray.100",
            },
        },
    };
};

const baseStyleList: SystemStyleObject = {
    transitionProperty: "height 1s linear",
    border: "none",
    p: 2,
    shadow: "base",
    bg: "gray.50",
};

const baseStyleItem: SystemStyleObject = {
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: "full",
    px: 4,
    py: 2,
    _hover: {
        _active: { bg: "gray.100" },
        _disabled: { bg: "white" },
    },
    _focusVisible: {
        ...focusOutline,
        borderRadius: "4px",
    },
    _focus: {
        background: "transparent",
    },
    _active: { bg: "black", color: "white" },
};

const baseStyle: PartsStyleFunction<typeof selectDropdownAnatomy> = () => ({
    button: baseStyleButton(),
    list: baseStyleList,
    item: baseStyleItem,
});

export const selectDropdownStyles = {
    baseStyle,
    parts: selectDropdownAnatomy.keys,
};
