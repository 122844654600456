import type { SystemStyleFunction, SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    px: 1,
    textTransform: "uppercase",
    fontSize: "xs",
    fontWeight: "bold",
};

const variantSolid: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    return {
        bg: `${c}.500`,
        color: "whiteAlpha.900",
    };
};

const variantSubtle: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    return {
        bg: `${c}.100`,
        color: `${c}.800`,
    };
};

const variantOutline: SystemStyleFunction = (props) => {
    const { colorScheme: c } = props;
    const color = c;

    return {
        color,
        boxShadow: `inset 0 0 0px 1px ${color}`,
    };
};

const variants = {
    solid: variantSolid,
    subtle: variantSubtle,
    outline: variantOutline,
};

const defaultProps = {
    variant: "subtle",
    colorScheme: "gray",
};

export const badgeStyles = {
    baseStyle,
    variants,
    defaultProps,
};
