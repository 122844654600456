import type { progressAnatomy as parts } from "@chakra-ui/anatomy";
import type { ComponentStyleConfig } from "@chakra-ui/react";
import type { PartsStyleObject } from "@chakra-ui/theme-tools";

const buttonLoader: PartsStyleObject<typeof parts> = {
    track: {
        h: "0.1rem",
        bg: "gray.600",
        w: "100%",
    },
    filledTrack: {
        w: "100%",
        bg: "white",
    },
};

const black: PartsStyleObject<typeof parts> = {
    track: {
        h: "0.1rem",
        bg: "gray.200",
        w: "100%",
    },
    filledTrack: {
        w: "100%",
        bg: "black",
    },
};

const checkout: PartsStyleObject<typeof parts> = {
    track: {
        h: "0.1rem",
        bg: "gray.300",
        w: "30%",
    },
    filledTrack: {
        w: "100%",
        bg: "black",
    },
};

const primary: PartsStyleObject<typeof parts> = {
    filledTrack: {
        w: "100%",
        bg: "white",
    },
};

const variants = {
    buttonLoader,
    primary,
    checkout,
    black,
};

export const progressStyles: ComponentStyleConfig = {
    variants,
};
