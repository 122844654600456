import space from "../03.space/space";

const container = {
    sx: "228px",
    sm: "375px",
    md: "810px",
    lg: "1440px",
    desktop: "1440px",
    mobile: "375px",
};

const topbar = {
    sm: "54px",
    md: "75px",
};

const icon = {
    "3xl": "80px",
    "2xl": "64px",
    xl: "40px",
    lg: "32px",
    md: "24px",
    sm: "20px",
    xs: "16px",
    "2xs": "12px",
    "3xs": "8px",
    "4xs": "52px",
};

const navBarCollapsedHeight = "52px";
const filtersDialogWidth = "656px";
const filtersDialogButtonsContainerHeight = "60px";
const navExpandedHeight = "80vh";

const countrySelector = {
    height: "284px",
    width: {
        base: "330px",
        tablet: "375px",
    },
};

const panelWidths = {
    smallPanelWidth: "424px",
    largePanelWidth: "868px",
    largeAndSmallPanelsWidth: `${424 + 868}px`,
};

const sizes = {
    ...space,
    countrySelector,
    container,
    icon,
    topbar,
    checkbox: "18px",
    max: "max-content",
    min: "min-content",
    full: "100%",
    eccoLogo: "89px", // This is the width of the logo - height is automatic based on aspect ratio
    scrollbarWidth: "2px",
    navBarCollapsedHeight,
    filtersDialogButtonsContainerHeight,
    filtersDialogWidth,
    navExpandedHeight,
    smallPanelWidth: panelWidths.smallPanelWidth,
    largePanelWidth: panelWidths.largePanelWidth,
    largeAndSmallPanelsWidth: panelWidths.largeAndSmallPanelsWidth,
};

export { icon, container, panelWidths };

export default sizes;
