import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    pb: ["2.5", "5"],
    borderColor: "inherit",
};

const variants: Record<string, SystemStyleObject> = {
    border: {
        borderBottomWidth: "1px",
    },
    noBorder: {
        borderBottomWidth: 0,
    },
};

const defaultProps = {
    variant: "border",
};

export const panelHeaderStyles = {
    defaultProps,
    variants,
    baseStyle,
};
