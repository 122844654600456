import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    fontSize: ["mobileBodyTextSmall", null, "desktopBodyTextSmall"],
    fontWeight: "semibold",
    marginEnd: 3,
    mb: 1,
    transitionProperty: "common",
    transitionDuration: "normal",
    opacity: 1,
    _disabled: {
        opacity: 0.4,
    },
};

export const formLabelStyles = {
    baseStyle,
    variants: {
        small: {
            fontSize: "desktopBodyTextSmall",
        },
        tiny: {
            fontSize: ["mobileBodyTextTiny", null, "desktopBodyTextTiny"],
        },
    },
};
