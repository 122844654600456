import { accordionAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";
import { focusOutline } from "../../../css/02.focus/focus.styles";

const baseStyleContainer: SystemStyleObject = {
    borderTopWidth: 0,
    bg: "white",
    _last: {
        borderBottomWidth: 0,
    },
};

const baseStyleButton: SystemStyleObject = {
    transitionProperty: "common",
    transitionDuration: "normal",
    fontSize: "bodyTextSmall",
    fontWeight: "semibold",
    borderRadius: "4px",
    _focusVisible: {
        ...focusOutline,
    },
    _hover: {
        bg: "blackAlpha.50",
    },
    _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
    },
    _expanded: {
        borderBottomRadius: "0",
        bg: "white",
        pt: 0,
        px: 12,
        pb: 2,
    },
    px: 4,
    py: 2,
};

const baseStylePanel: SystemStyleObject = {
    pt: 0,
    px: 12,
    pb: 12,
};

const baseStyleIcon: SystemStyleObject = {
    fontSize: "1.25em",
};

const baseStyle: PartsStyleObject<typeof parts> = {
    root: {},
    container: baseStyleContainer,
    button: baseStyleButton,
    panel: baseStylePanel,
    icon: baseStyleIcon,
};

export const accordionStyles = {
    parts: parts.keys,
    baseStyle,
};
