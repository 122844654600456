export const scale = {
    tighter: "0px",
    tight: "0px",
    normal: "0.2px",
    wide: "0.4px",
    wider: "0.4px",
    widest: "0.4px",
};

export default scale;
