const scale = {
    none: 0,
    sm: 0,
    default: 0,
    md: 0,
    lg: 0,
    xl: 0,
    "2xl": 0,
    "3xl": 0,
    smallPill: 0,
    pill: 0,
    full: 0,
};

export default scale;
