import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyleText: SystemStyleObject = {
    color: "red.500",
    mt: 2,
    fontSize: "sm",
    lineHeight: "normal",
};

const baseStyleIcon: SystemStyleObject = {
    marginEnd: "0.5em",
    color: "red.500",
};

const baseStyle: PartsStyleObject<typeof parts> = {
    text: baseStyleText,
    icon: baseStyleIcon,
};

export const formErrorStyles = {
    parts: parts.keys,
    baseStyle,
};
