const outlineColor = "black";
export const focusOutline = {
    outline: "2px solid",
    outlineOffset: "2px",
    outlineColor,
    // We need this value, otherwise the focus will be hidden by the next element's border
    zIndex: 5,
};

export const focusOutlineThin = {
    outlineOffset: "2px",
    outline: "1px solid",
    outlineColor,
};

export const focusOutlineWide = {
    outlineOffset: "4px",
    outline: "2px solid",
    outlineColor,
};

export const focusTextOutlne = {};
