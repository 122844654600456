import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    w: "100%",
    mx: "auto",
};

const sizes: Record<string, SystemStyleObject> = {
    sm: { maxW: "container.sm" },
    md: { maxW: "container.md" },
    lg: { maxW: "container.lg" },
    desktop: { maxW: "container.desktop", px: [4, 4, 16] },
    mobile: { maxW: "container.mobile" },
};

const defaultProps = {
    size: "md",
};

export const containerStyles = {
    baseStyle,
    sizes,
    defaultProps,
};
