import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";
import { productRowAnatomy } from "./ProductRow.anatomy";

const linkStyle: SystemStyleObject = {
    textDecoration: "none",
    position: "relative",
    _hover: {
        textDecoration: "none",
    },
    w: "inherit",
};

const imageStyle: SystemStyleObject = {
    display: "inline-block",
    objectFit: "cover",
    aspectRatio: "3/2",
};

const headlineStyle: SystemStyleObject = {
    fontWeight: "semibold",
};

const priceBoxStyle: SystemStyleObject = {
    textAlign: "right",
};

const priceNowStyle: SystemStyleObject = {
    fontWeight: "semibold",
    color: "black",
    mr: 2.5,
};

const priceBeforeStyle: SystemStyleObject = {
    color: "gray.600",
    fontWeight: "semibold",
    textDecoration: "line-through",
};

const descriptionStyle: SystemStyleObject = {
    fontWeight: "semibold",
};

const quantityTextStyle: SystemStyleObject = {
    fontWeight: "semibold",
};

const colorSizeBoxStyle: SystemStyleObject = {};

const sizeStyle: SystemStyleObject = {
    fontWeight: "semibold",
};

const colorStyle: SystemStyleObject = {
    fontWeight: "semibold",
};

const row: SystemStyleObject = {
    display: "flex",
    justifyContent: "start",
    gap: 15,
    background: "white",
    p: 2,
};

const baseStyle: PartsStyleObject<typeof productRowAnatomy> = {
    root: row,
    link: linkStyle,
    image: imageStyle,
    headline: headlineStyle,
    description: descriptionStyle,
    sizeColorBox: colorSizeBoxStyle,
    sizeStyle: sizeStyle,
    colorStyle: colorStyle,
    quantityText: quantityTextStyle,
    price: priceBoxStyle,
    priceNow: priceNowStyle,
    priceBefore: priceBeforeStyle,
};

export const productRowStyles = {
    parts: productRowAnatomy.keys,
    baseStyle,
};
