import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    lineHeight: "shorter",
    borderRadius: "full",
    px: 6,
    py: 3,
    fontWeight: "semibold",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
        boxShadow: "none",
    },
    _focus: {
        outlineOffset: "2px",
        outline: "1px solid black",
    },
    _hover: {
        _disabled: {
            bg: "initial",
        },
    },
};

export const iconButtonStyles = {
    baseStyle,
};
