import type { SystemStyleObject } from "@chakra-ui/theme-tools";
import { whiten } from "@chakra-ui/theme-tools";
import { focusOutline } from "../../../css/02.focus/focus.styles";

const baseStyle: SystemStyleObject = {
    display: "inline-block",
    transitionProperty: "common",
    transitionDuration: "fast",
    transitionTimingFunction: "ease-out",
    cursor: "pointer",
    outline: "none",
    color: "black",

    fontWeigh: "normal",
    lineHeight: "base",

    _focusVisible: {
        ...focusOutline,
        zIndex: "1",
        position: "relative",
    },
    _hover: {
        color: whiten("black", 40),
        textDecoration: "underline",
    },
    _active: {
        outline: "0",
        textDecoration: "underline",
    },
};

const variants: Record<string, SystemStyleObject> = {
    l1: {
        fontSize: ["mobileLink1", "tabletLink1", "desktopLink1"],
        fontWeight: "bold",
        letterSpacing: "normal",
    },
    l2: {
        fontSize: ["mobileLink2", "tabletLink2", "desktopLink2"],
        fontWeight: "bold",
        letterSpacing: "wide",
    },
    l3: {
        fontSize: ["mobileLink3", "tabletLink3", "desktopLink3"],
        fontWeight: "bold",
        letterSpacing: "normal",
        textDecoration: "underline",
    },
    l4: {
        fontSize: ["mobileLink4", "tabletLink4", "desktopLink4"],
        letterSpacing: "normal",
        textDecoration: "underline",
    },
};

const defaultProps = {
    variant: "l3",
};

export const linkStyles = {
    baseStyle,
    variants,
    defaultProps,
};
