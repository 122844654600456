import type { SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = () => {
    return {
        borderStyle: "solid",
        borderWidth: "1px",
        fontWeight: "bold",
        fontSize: ["mobileBodyB2", "desktopBodyB2"],
        color: "gray.500",
        borderRadius: "full",
        borderColor: "gray.400",
        _selected: {
            outline: "none",
        },
        _focus: {
            zIndex: 1,
            transitionProperty: "none",
            transitionDuration: "0s",
            outlineWidth: ".5px",
            outlineStyle: "solid",
            outlineColor: "black",
            outlineOffset: "0",
            boxShadow: "none",
        },
    };
};

export const textAreaStyles = {
    baseStyle,
};
