import { formAnatomy as parts } from "@chakra-ui/anatomy";
import type { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyleRequiredIndicator: SystemStyleObject = {
    display: "none",
};

const baseStyleHelperText: SystemStyleObject = {
    mt: 2,
    color: "gray.500",
    lineHeight: "normal",
    fontSize: "xs",
};

const baseStyle: PartsStyleObject<typeof parts> = {
    container: { width: "100%", position: "relative", alignItems: "flex-end" },
    requiredIndicator: baseStyleRequiredIndicator,
    helperText: baseStyleHelperText,
};

export const formStyles = {
    parts: parts.keys,
    baseStyle,
};
