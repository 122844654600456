import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import type { CheckboxProps } from "@chakra-ui/react";
import type {
    PartsStyleFunction,
    PartsStyleObject,
    SystemStyleObject,
} from "@chakra-ui/theme-tools";
import { focusOutline } from "../../../css/02.focus/focus.styles";
import { noHoverOnTouchDevice } from "../../../css/common";

const baseStyleControl = (): SystemStyleObject => {
    // Eventually maybe there is different scenario where the checkbox has to be in another color
    const base = {
        w: "100%",
        boxShadow: "none",
        transitionProperty: "box-shadow",
        transitionDuration: "normal",
        border: "1.2px solid",
        borderRadius: "default",
        borderColor: "black",
    };

    return {
        ...base,
        "@media(hover: hover)": {
            _hover: {
                borderColor: "black",
            },
            borderColor: "black",
        },
        _focus: {
            shadow: "none",
            boxShadow: "none",
            ...focusOutline,
        },
        _checked: {
            bg: "black",
            borderColor: "black",
            color: "white",

            "@media(hover: hover)": {
                _hover: {
                    bg: "black",
                    borderColor: "black",
                },
            },

            _disabled: {
                borderColor: "gray.200",
                bg: "gray.200",
                color: "gray.500",
            },
        },

        _indeterminate: {
            color: "white",
            borderColor: "black",
            bg: "black",
        },

        _disabled: {
            bg: "gray.200",
            cursor: "default",
            borderColor: "gray.200",
        },

        _invalid: {
            borderColor: "red.500",
        },
    };
};

const baseStyleContainer: SystemStyleObject = {
    _disabled: { cursor: "not-allowed" },
    _focus: {
        outline: "none",
    },
    "@media(hover: hover)": {
        _hover: {
            borderColor: "black",
        },
    },
};

const baseStyleLabel: SystemStyleObject = {
    userSelect: "none",
    position: "relative",
    [noHoverOnTouchDevice]: {
        _hover: "underline",
    },
    _disabled: {
        opacity: 1,
        p: {
            color: "gray.200",
        },
    },
};

const baseStyleIcon: SystemStyleObject = {
    transitionProperty: "transform",
    transitionDuration: "normal",
};

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
    icon: baseStyleIcon,
    container: baseStyleContainer,
    control: baseStyleControl(),
    label: baseStyleLabel,
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    sm: {
        control: { h: "18px", w: "18px" },
        label: { fontSize: "sm" },
        icon: { fontSize: "0.45rem" },
    },
    md: {
        control: { w: "18px", h: "18px" },
        label: { fontSize: "md", fontWeight: "bold" },
        icon: { fontSize: "0.625rem" },
    },
    lg: {
        control: { w: "18px", h: "18px" },
        label: { fontSize: "lg" },
        icon: { fontSize: "0.625rem" },
    },
};

const defaultProps: CheckboxProps = {
    size: "md",
    colorScheme: "black",
};

export const checkboxStyles = {
    parts: parts.keys,
    baseStyle,
    sizes,
    defaultProps,
};
