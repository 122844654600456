import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {
    pt: ["5", "10"],
    borderTopWidth: "1px",
    borderColor: "inherit",
};

export const panelFooterStyles = {
    baseStyle,
};
