import type { SystemStyleObject } from "@chakra-ui/theme-tools";

const sizes: Record<string, SystemStyleObject> = {
    inherit: {
        w: "1em",
        h: "1em",
    },
    "3xs": {
        w: "icon.3xs",
        h: "icon.3xs",
    },
    "2xs": {
        w: "icon.2xs",
        h: "icon.2xs",
    },
    xs: {
        w: "icon.xs",
        h: "icon.xs",
    },
    sm: {
        w: "icon.sm",
        h: "icon.sm",
    },
    md: {
        w: "icon.md",
        h: "icon.md",
    },
    lg: {
        w: "icon.lg",
        h: "icon.lg",
    },
    xl: {
        w: "icon.xl",
        h: "icon.xl",
    },
    "2xl": {
        w: "icon.2xl",
        h: "icon.2xl",
    },
    "3xl": {
        w: "icon.3xl",
        h: "icon.3xl",
    },
};

const defaultProps = {
    size: "md",
};

export const eccoIconStyles = {
    defaultProps,
    sizes,
};
