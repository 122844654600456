import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import type {
    PartsStyleFunction,
    PartsStyleObject,
    SystemStyleFunction,
    SystemStyleObject,
} from "@chakra-ui/theme-tools";

const baseStyleOverlay: SystemStyleObject = {
    bg: "blackAlpha.600",
    zIndex: "modal",
};

const baseStyleDialogContainer: SystemStyleFunction = (props) => {
    const { isCentered, scrollBehavior } = props;

    return {
        display: "flex",
        zIndex: "modal",
        justifyContent: "center",
        alignItems: isCentered ? "center" : "flex-start",
        overflow: scrollBehavior === "inside" ? "hidden" : "auto",
    };
};

const baseStyleDialog: SystemStyleFunction = (props) => {
    const { scrollBehavior } = props;

    return {
        px: [3, 4, 6],
        py: [3, 4, 6],
        gap: [4, 6],
        borderRadius: "md",
        bg: "white",
        color: "inherit",
        my: "3.75rem",
        zIndex: "modal",
        maxH: scrollBehavior === "inside" ? "calc(100% - 7.5rem)" : undefined,
        boxShadow: "lg",
    };
};

const baseStyleHeader: SystemStyleObject = {
    pl: 0,
    // Bigger right padding so the close button does not cover the content.
    pr: 6,
    py: 0,
};

const baseStyleCloseButton: SystemStyleObject = {
    position: "absolute",
    top: 0,
    insetEnd: 3,
    zIndex: "modal",
};

const baseStyleBody: SystemStyleFunction = (props) => {
    const { scrollBehavior } = props;
    return {
        px: 0,
        py: 0,
        flex: 1,
        overflow: scrollBehavior === "inside" ? "auto" : undefined,
    };
};

const baseStyleFooter: SystemStyleObject = {
    py: 0,
    px: 0,
    justifyContent: "start",
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    overlay: baseStyleOverlay,
    dialogContainer: baseStyleDialogContainer(props),
    dialog: baseStyleDialog(props),
    header: baseStyleHeader,
    closeButton: baseStyleCloseButton,
    body: baseStyleBody(props),
    footer: baseStyleFooter,
});

/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize(value: string): PartsStyleObject<typeof parts> {
    if (value === "full") {
        return {
            dialog: {
                maxW: "100vw",
                minH: "100vh",
                my: 0,
                borderRadius: 0,
            },
        };
    }
    return {
        dialog: { maxW: value, height: "auto" },
    };
}

const sizes = {
    xs: getSize("xs"),
    sm: getSize("sm"),
    md: getSize("md"),
    lg: getSize("104"),
    xl: getSize("xl"),
    "2xl": getSize("2xl"),
    "3xl": getSize("3xl"),
    "4xl": getSize("4xl"),
    "5xl": getSize("5xl"),
    "6xl": getSize("6xl"),
    full: getSize("full"),
};

const defaultProps = {
    size: "lg",
};

export const modalStyles = {
    parts: parts.keys,
    baseStyle,
    sizes,
    defaultProps,
};
